<template>
    <div>
        <ddcard class="box-card" v-loading="!hasUserInfo">
            <div slot="title">
                <svg-icon icon-class="unlink" class="dashboard-icon-card" />
                <span class="dd-title">Promoveaza-ti produsele de pe CEL.ro pe site-ul tau</span>
            </div>
            <el-alert v-if="hasCodeOnSite" title="Codul este prezent in pagina setata!" type="success" show-icon>
            </el-alert>
            <el-alert
                v-else-if="!hasCodeOnSite && hasSiteUrl"
                title="Codul nu este prezent in pagina setata!"
                type="error"
                show-icon
            >
            </el-alert>
            <div class="mini-separator"></div>
            <div class="mini-title">Pasul 1</div>
            <!-- {{$t('dashboard.right.wrapper-description')}} -->
            <div v-html="description"></div>
            <div class="separator"></div>
            <div class="mini-title">COD 1</div>
            <a :href="linkOferta" target="_blank">
                <img src="https://s1.cel.ro/images/logo_backlink.png" alt="www.cel.ro" border="0" />
            </a>
            <pre v-highlightjs="highlightCodeLogo"><code class="html code" @click="doCopy(logoCode)"></code></pre>
            <div class="separator"></div>
            <div class="mini-title">COD 2</div>
            <a :href="linkOferta" target="_blank"> Vezi oferta pe CEL.ro </a>
            <pre v-highlightjs="highlightCodeText"><code class="html code" @click="doCopy(textCode)"></code></pre>
            <div class="mini-title">Pasul 2</div>
            Introdu linkul catre pagina care contine codul de mai sus.
            <div class="mini-separator"></div>
            <el-input placeholder="www.siteulmeu.ro" :value="siteUrl" @input="editSiteUrl">
                <el-button class="b-c-sls" slot="append" icon="el-icon-check" @click="saveLinkSite">Salveaza</el-button>
            </el-input>
            <div class="mini-separator"></div>
            <el-button v-if="hasSiteUrl" plain class="fullWidth b-c-csu" type="primary" @click="checkSiteUrl"
                >Verifica status cod
            </el-button>
            <!-- <div class="tip-toe">Poti modifica oricand linkul catre pagina de promovare <router-link
					to="/cont/informatii">aici</router-link>
			</div> -->
        </ddcard>
    </div>
</template>

<script>
// Import Vue and vue-highlgihtjs
import Vue from 'vue';
import VueHighlightJS from 'vue-highlightjs';
const ddcard = () => import('@/components/DDCard');

// Tell Vue.js to use vue-highlightjs
Vue.use(VueHighlightJS);

import { mapGetters, mapState } from 'vuex';

import { validateUrl } from '@/utils/index';

export default {
    components: {
        ddcard,
    },
    data() {
        return {
            siteLink: '',
            isLoading: false,
        };
    },
    computed: {
        ...mapGetters({
            hasSiteUrl: 'user/hasSiteUrl',
            linkOferta: 'user/linkOferta',
        }),
        ...mapState({
            siteUrl: (state) =>
                state.user.info != null && state.user.info.siteUrl != null ? state.user.info.siteUrl : '',
            emailDomain: (state) =>
                state.user.info != null && state.user.info.emailDomain != null ? state.user.info.emailDomain : '',
            hasUserInfo: (state) => state.user.info != null && Object.keys(state.user.info).length > 0,
        }),
        logoCode() {
            return (
                '<a href="' +
                this.linkOferta +
                '" target="_blank"><img alt="www.cel.ro" src="https://s1.cel.ro/images/logo_backlink.png" border="0"></a>'
            );
        },
        textCode() {
            return '<a href="' + this.linkOferta + '" target="_blank">Vezi oferta pe CEL.ro</a>';
        },
        highlightCodeLogo() {
            return (
                '<a href="' +
                this.linkOferta +
                '" target="_blank"> \n\    <img src="https://s1.cel.ro/images/logo_backlink.png" alt="www.cel.ro" border="0"> \n\</a>'
            );
        },
        highlightCodeText() {
            return '<a href="' + this.linkOferta + '" target="_blank"> \n\    Vezi oferta pe CEL.ro \n\</a>';
        },
        description() {
            return (
                'Pentru a beneficia de promovarea produselor tale prin intermediul bugetului nostru de marketing cat si \
						pentru aparitia \
						brandului tau la fiecare produs afisat pe CEL.ro este necesar sa inserezi in site-ul tau' +
                (this.emailDomain !== ''
                    ? ` (<a style="color: #ff601d" target="_blank" href="http://${this.emailDomain}">${this.emailDomain}</a>)`
                    : '') +
                ' , de preferat pe \
						home sau intr-o pagina \
						usor accesibila unul din urmatoarele coduri:'
            );
        },
        hasCodeOnSite() {
            return this.$store.state.user.info != null && this.$store.state.user.info.hasCodeOnSite == 1;
        },
    },
    methods: {
        doCopy(code) {
            this.$copyText(code).then(
                (e) => {
                    this.sbMsg({
                        type: 'success',
                        message: 'Codul a fost copiat in clipboard',
                    });
                },
                (e) => {
                    this.sbMsg({
                        type: 'warn',
                        message: 'Codul nu a putut fi copiat in clipboard. Selectati si copiati manual.',
                    });
                    console.log(e);
                }
            );
        },
        async saveLinkSite() {
            if (this.siteUrl === '') {
                this.sbMsg({
                    type: 'warn',
                    message: 'Nu ai introdus linkul catre site',
                });
                return false;
            }
            if (!validateUrl(this.siteUrl)) {
                this.sbMsg({
                    type: 'warn',
                    message: 'Linkul introdus este invalid',
                });
                return false;
            }
            try {
                await this.$store.dispatch('dashboard/SaveAffiliateLink', this.siteUrl.trim());
                this.sbMsg({
                    type: 'success',
                    message: 'Linkul a fost salvat!',
                });
            } catch (err) {}
        },
        async checkSiteUrl() {
            try {
                const status = await this.$store.dispatch('dashboard/CheckSiteUrl');
                if (status.message.status == 0) {
                    this.sbMsg({
                        type: 'warn',
                        message: 'Pagina nu contine unul din codurile de la pasul 1!',
                    });
                    return false;
                }
                this.sbMsg({
                    type: 'success',
                    message: 'Pagina setata contine unul din codurile de la pasul 1!',
                });
                this.$store.commit('user/SET_USER_HAS_CODE', 1);
            } catch (err) {}
        },
        editSiteUrl(value) {
            this.$store.commit('user/SET_USER_LINK', value);
        },
    },
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.head-title {
    vertical-align: middle;
    font-weight: 500;
    margin-left: 3px;
}

.mini-title {
    font-size: 15px;
    font-weight: 500;
    padding-bottom: 10px;
    font-weight: bold;
    text-transform: uppercase;
    text-decoration: underline;
}

.card-body {
    font-size: 13px;
}

a {
    color: #ff601d;
}

.code {
    cursor: pointer;
    font-size: 0.9em;
}

.tip-toe {
    margin-top: 10px;
    font-size: 12px;
    font-style: italic;
}
</style>
